import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { grey, blue, pink, teal, amber } from '@mui/material/colors';

const commonSettings = {
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h1: {
      fontSize: '3rem',
      '@media (min-width:600px)': {
        fontSize: '3.5rem',
      },
      fontWeight: 700,
    },
    h2: {
      fontSize: '2.5rem',
      '@media (min-width:600px)': {
        fontSize: '3rem',
      },
      fontWeight: 700,
    },
    h3: {
      fontSize: '2rem',
      '@media (min-width:600px)': {
        fontSize: '2.5rem',
      },
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.75rem',
      '@media (min-width:600px)': {
        fontSize: '2rem',
      },
      fontWeight: 600,
    },
    h5: {
      fontSize: '1.5rem',
      '@media (min-width:600px)': {
        fontSize: '2rem',
      },
      fontWeight: 600,
    },
    h6: {
      fontSize: '1.25rem',
      '@media (min-width:600px)': {
        fontSize: '1.5rem',
      },
      fontWeight: 600,
    },
    body1: {
      fontSize: '1rem',
      '@media (min-width:600px)': {
        fontSize: '1.125rem',
      },
    },
    body2: {
      fontSize: '0.875rem',
      '@media (min-width:600px)': {
        fontSize: '1rem',
      },
    },
  },
  spacing: 8, // Default spacing unit
  shape: {
    borderRadius: 12,
  },
};

const lightTheme = createTheme({
  ...commonSettings,
  palette: {
    mode: 'light',
    primary: {
      main: blue[600],
      contrastText: '#fff',
    },
    secondary: {
      main: pink[600],
      contrastText: '#fff',
    },
    background: {
      default: grey[100],
      paper: '#fff',
    },
    text: {
      primary: grey[900],
      secondary: grey[700],
    },
    success: {
      main: teal[600],
    },
    warning: {
      main: amber[700],
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#ffffff',
          color: blue[600],
          boxShadow: 'none',
          borderBottom: `1px solid ${grey[200]}`,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 25,
          textTransform: 'none',
          padding: '10px 20px',
          fontWeight: 600,
        },
        containedPrimary: {
          backgroundColor: blue[600],
          '&:hover': {
            backgroundColor: blue[700],
          },
        },
        containedSecondary: {
          backgroundColor: pink[600],
          '&:hover': {
            backgroundColor: pink[700],
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto, sans-serif',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: 12,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: grey[400],
          '&.Mui-checked': {
            color: blue[600],
          },
          '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: blue[500],
          },
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          borderTop: `1px solid ${grey[200]}`,
        },
      },
    },
  },
});

const darkTheme = createTheme({
  ...commonSettings,
  palette: {
    mode: 'dark',
    primary: {
      main: blue[300],
      contrastText: '#000',
    },
    secondary: {
      main: pink[300],
      contrastText: '#000',
    },
    background: {
      default: grey[900],
      paper: grey[800],
    },
    text: {
      primary: '#fff',
      secondary: grey[400],
    },
    success: {
      main: teal[300],
    },
    warning: {
      main: amber[500],
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: grey[900],
          color: blue[300],
          boxShadow: 'none',
          borderBottom: `1px solid ${grey[700]}`,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 25,
          textTransform: 'none',
          padding: '10px 20px',
          fontWeight: 600,
        },
        containedPrimary: {
          backgroundColor: blue[300],
          '&:hover': {
            backgroundColor: blue[400],
          },
        },
        containedSecondary: {
          backgroundColor: pink[300],
          '&:hover': {
            backgroundColor: pink[400],
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.5)',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.5)',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto, sans-serif',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: 12,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: grey[600],
          '&.Mui-checked': {
            color: blue[300],
          },
          '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: blue[400],
          },
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          borderTop: `1px solid ${grey[700]}`,
        },
      },
    },
  },
});

// Apply responsive font sizes
const finalLightTheme = responsiveFontSizes(lightTheme);
const finalDarkTheme = responsiveFontSizes(darkTheme);

export { finalLightTheme as lightTheme, finalDarkTheme as darkTheme };