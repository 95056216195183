import React from 'react';
import { CircularProgress, Box, Fade } from '@mui/material';

const Loading = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
    <Fade in>
      <CircularProgress size={60} thickness={4} />
    </Fade>
  </Box>
);

export default Loading;