import React, { useState, Suspense, lazy, useMemo, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import {
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  BottomNavigation,
  BottomNavigationAction,
  Menu,
  MenuItem,
  Avatar,
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BookIcon from '@mui/icons-material/Book';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Loading from './components/Loading';
import { useAuth } from './contexts/AuthContext';
import { lightTheme, darkTheme } from './theme';
import useMediaQuery from '@mui/material/useMediaQuery';

const Dashboard = lazy(() => import('./components/Dashboard'));
const RecentEntries = lazy(() => import('./components/RecentEntries'));
const Login = lazy(() => import('./components/Login'));
const Register = lazy(() => import('./components/Register'));
const AccountSettings = lazy(() => import('./components/AccountSettings'));

function App() {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [darkMode, setDarkMode] = useState(prefersDarkMode);
  const [currentPage, setCurrentPage] = useState('dashboard');
  const [entries, setEntries] = useState(() => JSON.parse(localStorage.getItem('entries')) || []);
  const [userProfile, setUserProfile] = useState({});
  const theme = useMemo(() => (darkMode ? darkTheme : lightTheme), [darkMode]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(null);

  useEffect(() => {
    if (currentUser) {
      const savedSettings = JSON.parse(localStorage.getItem('userSettings'));
      if (savedSettings && savedSettings.profilePhoto) {
        setProfilePhoto(savedSettings.profilePhoto);
      } else if (currentUser.photoURL) {
        setProfilePhoto(currentUser.photoURL);
      }
    }
  }, [currentUser]);

  const handleUpdateProfile = (updatedProfile) => {
    setUserProfile(updatedProfile);
    if (updatedProfile.profilePhoto) {
      setProfilePhoto(updatedProfile.profilePhoto);
    }
  };

  const handleLogout = async () => {
    console.log('Logout button clicked');
    try {
      await logout();
      console.log('User logged out successfully');
      navigate('/login');
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSettingsClick = () => {
    setCurrentPage('account');
    handleClose();
  };

  console.log('Current user:', currentUser ? 'Logged in' : 'Not logged in');

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Edios
            </Typography>
            {currentUser && (
              <div>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <Avatar alt={currentUser.displayName} src={profilePhoto} />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => { setDarkMode(!darkMode); handleClose(); }}>
                    {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
                    {darkMode ? 'Light Mode' : 'Dark Mode'}
                  </MenuItem>
                  <MenuItem onClick={handleSettingsClick}>
                    <SettingsIcon /> Settings
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>
                    <ExitToAppIcon /> Logout
                  </MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </AppBar>
        <Box component="main" sx={{ flexGrow: 1, p: 3, backgroundColor: theme.palette.background.default }}>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="/login" element={!currentUser ? <Login /> : <Navigate to="/" />} />
              <Route path="/register" element={!currentUser ? <Register /> : <Navigate to="/" />} />
              <Route path="/" element={currentUser ? (
                currentPage === 'dashboard' ? <Dashboard entries={entries} setEntries={setEntries} user={currentUser} /> :
                currentPage === 'recentEntries' ? <RecentEntries entries={entries} /> :
                <AccountSettings user={userProfile} onUpdateProfile={handleUpdateProfile} />
              ) : <Navigate to="/login" />} />
            </Routes>
          </Suspense>
        </Box>
        {currentUser && (
          <BottomNavigation
            value={currentPage}
            onChange={(event, newValue) => {
              setCurrentPage(newValue);
            }}
            showLabels
            sx={{ borderTop: 1, borderColor: 'divider' }}
          >
            <BottomNavigationAction label="Dashboard" value="dashboard" icon={<DashboardIcon />} />
            <BottomNavigationAction label="Recent Entries" value="recentEntries" icon={<BookIcon />} />
          </BottomNavigation>
        )}
      </Box>
    </ThemeProvider>
  );
}

export default App;
